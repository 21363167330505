import { Inject, Injectable } from '@angular/core'
import { BehaviorSubject, map, Observable } from 'rxjs'
import { IAccountModel } from '../../common/models/domain/models'
import { ICompany, ICompanyModel } from '../../common/models/domain/models/company.model'
import { LOCAL_STORAGE_KEYS } from '../local-storage/local-storage.key'
import { LocalStorageService } from '../local-storage/local-storage.service'

@Injectable()
export class AccountService {
  public account$: BehaviorSubject<Partial<IAccountModel> | undefined> = new BehaviorSubject<Partial<IAccountModel> | undefined>(undefined)
  public companyLogoUrl: string

  constructor(
    @Inject(Window)
    private readonly windowRef: Window,
    private readonly localeStorage: LocalStorageService
  ) {
    this.companyLogoUrl = `https://storage.googleapis.com/${ this.getBucket() }/logos/${ this.getCompanyDomain() }`
  }

  private getBucket(): string {
    switch (this.windowRef.location.hostname) {
      case 'localhost':
      case 'dev-client.hypernet.pro':
        return 'htn-public-dev'
      default:
        return 'htn-public'
    }
  }

  private getCompanyDomain(): string {
    switch (this.windowRef.location.hostname) {
      case 'localhost':
        return 'dev-secured.hypernet.pro'
      default:
        return this.windowRef.location.hostname
    }
  }

  public get subscriptionId$(): Observable<string | undefined> {
    return this.account$.pipe(
      map((a: Partial<IAccountModel> | undefined) => a?.subscription?.id)
    )
  }

  public get companiesCount$(): Observable<number> {
    return this.account$.pipe(
      map((a: Partial<IAccountModel> | undefined) => a?.companies?.length || 0)
    )
  }

  public get currentCompany$(): Observable<ICompany | undefined> {
    return this.account$
      .pipe(
        map((a: Partial<IAccountModel> | undefined) => a?.currentCompany)
      )
  }

  public getCurrentCompany(): ICompanyModel | undefined {
    return this.account$?.getValue()?.currentCompany
  }

  public getSubscriptionId(): string | undefined {
    return this.account$?.getValue()?.subscription?.id
  }

  public getAccountId(): string | undefined {
    return this.account$?.getValue()?.id
  }

  public getCurrentCompanyId(): string | undefined {
    return this.getCurrentCompany()?.id
  }

  public get accountFullName(): string | undefined {
    return `${this.account$?.getValue()?.firstName} ${this.account$?.getValue()?.lastName}`
  }

  public get accountEmail(): string | undefined {
    return this.account$?.getValue()?.email
  }

  public changeCompany(company: ICompanyModel): void {
    /*this.account$.next({
      ...this.account$.value,
      currentCompany: company
    });*/
    this.localeStorage.setItem(LOCAL_STORAGE_KEYS.COMPANY, company)
    this.windowRef.location.reload()
  }

}
